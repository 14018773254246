<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="false"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <debtor-adjustment-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(entity, index) in entitySummaries"
                :key="entity.invoiceId"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td v-if="entity.invoiceType === invoiceTypes.Adjustment">
                  <a @click.prevent="edit(entity.invoiceId, entity.invoiceType, $event)"
                    :href="generateUrl(entity.invoiceId)">{{ entity.invoiceRef }}</a>
                </td>
                <td v-else-if="entity.invoiceType !== invoiceTypes.Adjustment && entity.totalIncGst !== 0">
                  <a @click.prevent="addnew(entity.invoiceId)"
                    :href="generateUrl(entity.invoiceId)">{{ entity.invoiceRef }}</a>
                </td>
                <td v-else>
                  {{ entity.invoiceRef }}
                </td>
                <td>{{ entity.invoiceType | formatInvoiceType }}</td>
                <td>{{ $filters.formatDateTimezone(entity.invoiceDate, $userInfo.locale) }}</td>
                <td class="has-text-right">{{ entity.totalIncGst | formatNumber($userInfo.locale) }}</td>
                <td>{{ entity.customerName }}</td>
                <td>{{ entity.contactName }}</td>
                <td>{{ entity.quoteNo }}</td>
                <td v-if="entity.invoiceType === invoiceTypes.Adjustment"
                  class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="edit(entity.invoiceId, entity.invoiceType, $event)"
                    :href="generateUrl(entity.invoiceId)"
                    data-tooltip="Click to edit">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
                <td v-else-if="entity.invoiceType !== invoiceTypes.Adjustment && entity.totalIncGst !== 0"
                  class="has-text-centered">
                  <a class="button is-primary is-small is-inverted tooltip"
                    @click.prevent="addnew(entity.invoiceId)"
                    :href="generateUrl(entity.invoiceId)"
                    data-tooltip="Click to add new">
                    <span class="icon is-medium">
                      <i class="mdi mdi-lead-pencil mdi-24px" />
                    </span>
                  </a>
                </td>
                <td v-else />
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import DebtorAdjustmentListSideMenu from './DebtorAdjustmentListSideMenu'
import { Columns } from './columns'
import StoreMixin from './storeMixin'
import BulmaTable from '@/components/BulmaTable'
import { AppHeaderButtonTypes, InvoiceTypes } from '@/enums'
import DebtorAdjustmentService from './DebtorAdjustmentService'
import DebtorAdjustmentValidation from './DebtorAdjustmentValidation'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import _transform from 'lodash/transform'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'DebtorAdjustmentListView',
  components: {
    AppDetailHeader,
    BulmaTable,
    [DebtorAdjustmentListSideMenu.name] : DebtorAdjustmentListSideMenu
  },
  filters: {
    formatInvoiceType(type) {
      const invoiceType = Object.keys(InvoiceTypes).find(key => InvoiceTypes[key] === type)
      const invoiceTypeText = invoiceType.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g,  ' $1')
      return invoiceTypeText
    }
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin, DebtorAdjustmentValidation],
  props: {},
  data() {
    return {
      entitySummaries: {},
      totalRows: 0,
      filter: {
        invoiceNo: '',
        invoiceType: 0,
        cusName: '',
        fromDate: '',
        toDate: '',
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18,
        quoteNo: ''
      },
      selectedRow: null,
      isTableLoading: false
    }
  },
  computed: {
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    },
    columns() {
      return Columns
    },
    invoiceTypes() {
      return InvoiceTypes
    }
  },
  created() {
    this.getData()
  },
  mounted() {
    this.$v.filter.$touch()
  },
  methods: {
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    generateUrl(id) {
      return `debtoradjustments/${id}`
    },
    edit(id, invoiceType, event) {
      this.$router.push({
        name: 'DebtorAdjustmentDetail',
        params: { invoiceId: id }
      })
    },
    async addnew(applyToInvoiceId) {
      this.addStoreItem(applyToInvoiceId)
    },
    onHeaderButtonClicked(action) {
      switch (action) {
        // case AppHeaderButtonTypes.AddNew:
        //   this.addNew()
        //   break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    filterList() {
      if (this.$v.filter.$error) {
        this.$notification.openNotificationWithType('danger', 'Debtor Adjustment', 'Filter validation errors.')
        return false
      }
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getData()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getData()
    },
    resetList() {
      this.filter.invoiceNo = ''
      this.filter.invoiceType = 0
      this.filter.cusName = ''
      this.filter.fromDate = ''
      this.filter.toDate = ''
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.filter.quoteNo = ''
      this.persistFilter()
      this.getData()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getData()
    },
    async getData() {
      // this.$showSpinner()
      this.isTableLoading = true
      this.retrieveFilter()
      this.entitySummaries = await DebtorAdjustmentService.getEntitySummaries(this.filter)
      this.totalRows = this.entitySummaries.length > 0 ? this.entitySummaries[0].totalRows : 0
      this.isTableLoading = false
      // this.$hideSpinner()
    },
    print() {
      const params = Object.assign(
        {
          reportname: this.$route.meta.report,
          companyId: this.$userInfo.companyId,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      const paramsFlat = Object.keys(params)
        .map(e => `${e.toLowerCase()}=${params[e]}`)
        .join('&')
      const url = `${this.$route.meta.reportUrl}?${paramsFlat}`
      window.open(url, '_blank')
    },
    print1() {
      let params = Object.assign(
        {
          companyId: this.$userInfo.companyId,
          timezoneoffset: this.$filters.getSiteTimezoneOffset()
        },
        this.filter
      )
      params.pageIndex = 1
      params.pageSize = this.totalRows
      // Convert properties to lower case
      params = _transform(params, function(result, value, key) {
        if (key.toLowerCase() === 'sortorder') {
          result['sortdirection'] = value
        } else {
          result[key.toLowerCase()] = value
        }
      })
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e.toLowerCase()}=${params[e]}`)
      //     .join('&')
      // )
      // Create KeyValuePairs and add to vuex for later retrieval by PrintPreviewEx component
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Debtor Adjustment Listing'
      emailer.reportName = 'DebtorAdjustmentList'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        // name: PrintPreviewRoutes.PrintPreviewOld.name,
        params: { reportName: this.$route.meta.report },
        // query: { query: query, emailerId: emailer.id }
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    convertToKeyValuePairs(parameters) {
      const keyValuePairs = Object.keys(parameters).map(function(key) {
        return new KeyValuePairModel(key, parameters[key])
      })
      return keyValuePairs
    }
  }
}
</script>
